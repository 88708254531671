import { Component, Output, EventEmitter, OnInit, OnDestroy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil, tap, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as svgIcons from '@progress/kendo-svg-icons';

import { AuthService } from 'src/app/core/auth/auth.service';
import { appUser } from 'src/app/module/security/user/user.model';
import { SystemManagerService } from 'src/app/module/system/system-manager.service';

import packageJson from 'package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public authService = inject(AuthService);
  private translate = inject(TranslateService);
  private router = inject(Router);
  private systemManagerService = inject(SystemManagerService);

  @Output() toggleSidenav = new EventEmitter();

  public allIcons = svgIcons;
  private unsubNotifier = new Subject<void>();
  isLoading = false;
  langue = this.translate.currentLang;
  logState: boolean = false;
  currentUser: any;
  appVersion!: string;
  apiVersion!: string;

  constructor() {
    this.appVersion = packageJson.version;
  }

  ngOnInit(): void {
    // fetch version API
    this.systemManagerService
      .getBasicApiInfo()
      .pipe(take(1))
      .subscribe((data) => {
        this.apiVersion = data.WebApiVersion;
      });
    //on va ÉCOUTER le changement de langue, et déclencher un refresh au besoin
    this.translate.onLangChange
      .pipe(
        tap((event) => {
          this.langue = event.lang;
        })
      )
      .subscribe();

    //on va ÉCOUTER le changement d'uysager en cours, et déclencher un refresh au besoin.  Le déclenchement se fait dans authService, via une méthode qui fait un next sur le currentAuthUserBehavior
    this.authService.currentAuthUserBehavior
      .pipe(
        tap((x) => {
          //une fois que l'usager est changé, on rafraichit le header
          this.refreshHeaderUserchange(x);
        }),
        takeUntil(this.unsubNotifier)
      )
      .subscribe();
  }

  /** Cette méthode permet de rafraichir le header lorsque l'usager change.  Permet de centraliser le code de rafraichissement du header,
   *
   * @param newUser
   */
  refreshHeaderUserchange(newUser: appUser) {
    this.currentUser = newUser;
  }

  ngOnDestroy(): void {
    //LOG_BASE_LifeCycle - this.utilService.logInformation(`HeaderComponent->ngOnDestroy  - UserData:${this.currentUser().GetDebugUserInfo()}`);

    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  selectLangue(langue: string) {
    this.langue = langue;
    this.translate.use(langue);
  }

  onLogoutClick() {
    this.logState = false;
    this.authService.logout(true).pipe(takeUntil(this.unsubNotifier)).subscribe();
  }

  /** Cette méthode de TEST permet de déclencher un REFRESH des tokens, avec le RefershToken.
   *
   */
  public onCheckProfileClick() {
    /*
    // ce endpoint n'existe plus dans l'API solution Soutien
    this.authService
      .refreshAuthTokens()
      .pipe(
        tap((retour) => {
          console.log(retour);
        }),
        takeUntil(this.unsubNotifier)
      )
      .subscribe();
      */
  }
}
