import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  { path: '', redirectTo: '/auth/home', pathMatch: 'full' },

  // SECURITY MODULES --------------------------------------------------------------------------------

  // AUTH

  {
    path: 'auth',
    loadChildren: () => import('./module/security/auth/auth.module').then((m) => m.AuthModule),
  },

  // USER

  {
    path: 'user',
    loadChildren: () => import('./module/security/user/user.module').then((m) => m.UserModule),
  },

  // ROLE

  {
    path: 'role',
    loadChildren: () => import('./module/security/role/role.module').then((m) => m.RoleModule),
  },

  // account-receivable namespace --------------------------------------------------------------------------------

  // CUSTOMER

  {
    path: 'customer',
    loadChildren: () => import('./module/account-receivable/customer/customer.module').then((m) => m.CustomerModule),
    //canActivate: [MsalGuard],
  },

  // SERVICE CONTRACT

  {
    path: 'service-contract',
    loadChildren: () => import('./module/account-receivable/service-contract/service-contract.module').then((m) => m.ServiceContractModule),
    //canActivate: [MsalGuard],
  },

  // software-manager namespace --------------------------------------------------------------------------------

  // LICENSING

  {
    path: 'licencing',
    loadChildren: () => import('./module/software-manager/licencing/licencing.module').then((m) => m.LicencingModule),
  },

  // SOFTWARE

  {
    path: 'software',
    loadChildren: () => import('./module/software-manager/software/software.module').then((m) => m.SoftwareModule),
  },

  // SOFTWARE VERSION

  {
    path: 'software-version',
    loadChildren: () => import('./module/software-manager/software-version/software-version.module').then((m) => m.SoftwareVersionModule),
  },

  // ERP COMPAGNY CONNEXION INFO

  {
    path: 'erp-company-connexion-info',
    loadChildren: () =>
      import('./module/erp-connector/erp-company-connexion-info/erp-company-connexion-info.module').then((m) => m.ErpCompanyConnexionInfoModule),
  },

  // TASK HISTORY

  {
    path: 'task-history',
    loadChildren: () => import('./module/erp-connector/task-history/task-history.module').then((m) => m.TaskHistoryModule),
  },

  // SOFTWARE SESSION LOG

  {
    path: 'software-session-log',
    loadChildren: () => import('./module/software-manager/software-session-log/software-session-log.module').then((m) => m.SoftwareSessionLogModule),
  },

  // SYSTEM

  {
    path: 'system',
    loadChildren: () => import('./module/system/system.module').then((m) => m.SystemModule),
  },

  // FEUILLE DE TEMPS

  {
    path: 'fdt',
    loadChildren: () => import('./module/service/ticket-fdt/ticket-fdt.module').then((m) => m.TicketFdtModule),
  },

  // TICKET

  {
    path: 'ticket',
    loadChildren: () => import('./module/service/ticket/ticket.module').then((m) => m.TicketModule),
  },

  // TICKET TYPE

  {
    path: 'ticket-type',
    loadChildren: () => import('./module/service/ticket-type/ticket-type.module').then((m) => m.TicketTypeModule),
  },

  // SSO ERROR ROUTING (Needed for Error routing)

  {
    path: 'error',
    //component: HomeComponent,
    loadChildren: () => import('./module/security/auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
