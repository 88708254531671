import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout/layout.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [LayoutComponent, SidenavComponent, HeaderComponent],
  imports: [SharedModule],
  exports: [LayoutComponent],
})
export class LayoutModule {}
