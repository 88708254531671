<div class="si-header-container">
  <!-- LEFT CONTAINER -->

  <div class="si-header-left">
    <!-- TOGGLE SIDENAV BUTTON -->

    <button
      *ngIf="currentUser.isCurrentlyLogged"
      kendoButton
      [svgIcon]="allIcons.menuIcon"
      fillMode="flat"
      themeColor="light"
      (click)="toggleSidenav.emit()"
    ></button>

    <div class="si-header-version">{{ appVersion }} - {{ apiVersion }}</div>
  </div>

  <!-- RIGHT CONTAINER -->

  <div class="si-header-right">
    <!-- HELP EMAIL

    <a href="mailto:info@solutioninfomedia.com"
      ><kendo-svg-icon
        [icon]="allIcons.envelopeIcon"
        themeColor="primary"
      ></kendo-svg-icon
    ></a>
-->

    <!-- MENU LANGUE -->

    <kendo-menu>
      <kendo-menu-item>
        <ng-template kendoMenuItemTemplate>
          <kendo-svg-icon [icon]="allIcons.globeIcon" themeColor="primary"></kendo-svg-icon>
          {{ langue | uppercase }}
        </ng-template>
        <ng-template kendoMenuItemContentTemplate let-index="index">
          <div class="si-header-menu-container">
            <button kendoButton fillMode="flat" (click)="selectLangue('fr')">Français</button>
            <button kendoButton fillMode="flat" (click)="selectLangue('en')">English</button>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu>

    <!-- MENU USAGER (PROFIL / LOGOUT )-->

    <kendo-menu *ngIf="currentUser.isCurrentlyLogged">
      <kendo-menu-item>
        <!-- CURRENT USER INFO -->

        <ng-template kendoMenuItemTemplate>
          <kendo-svg-icon [icon]="allIcons.userIcon" class="si-header-menu-link"></kendo-svg-icon>
          {{ currentUser.userName }}
        </ng-template>

        <!-- MENU CONTENT -->

        <ng-template kendoMenuItemContentTemplate let-index="index">
          <div class="si-header-menu-container">
            <!-- PROFILE -->

            <button kendoButton fillMode="flat" [svgIcon]="allIcons.userIcon" (click)="onCheckProfileClick()" routerLink="/auth/profile">
              {{ "layout.header.button_profile" | translate }}
            </button>

            <!-- LOGOUT -->

            <button kendoButton fillMode="flat" [svgIcon]="allIcons.logoutIcon" (click)="onLogoutClick()">
              {{ "layout.header.button_logout" | translate }}
            </button>
          </div>
        </ng-template>
      </kendo-menu-item>
    </kendo-menu>
  </div>
</div>
