<div class="si-sidenav-main" [class.si-sidenav-main-mini]="!isExpanded">
  <!-- LOGO -->
  <a routerLink="/auth/home">
    <div class="si-sidenav-header-logo"></div>
  </a>
  <!-- MENU CONTAINER -->
  <div class="si-sidenav-menu-container">
    <!-- HOME -->
    <a class="si-sidenav-menu-item" routerLink="/auth/home" routerLinkActive="si-sidenav-menu-item-selected">
      <kendo-svg-icon [icon]="allIcons.homeIcon"></kendo-svg-icon>
      <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_home" | translate }}</span>
    </a>

    <!-- CUSTOMER -->

    <a *ngIf="canSeeSidenavCustomer" class="si-sidenav-menu-item" routerLink="/customer" routerLinkActive="si-sidenav-menu-item-selected">
      <kendo-svg-icon [icon]="allIcons.myspaceIcon"></kendo-svg-icon>
      <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_customer" | translate }}</span></a
    >

    <!-- SERVICE : EXPANDABLE -->

    <kendo-expansionpanel
      class="si-sidenav-menu-item-expansionpanel"
      [class.si-sidenav-menu-item-expansionpanel-opened]="serviceExpanded"
      *ngIf="canSeeSidenavFdt || canSeeSidenavTicket"
      [expanded]="serviceExpanded"
      (expandedChange)="onExpandedChange($event, 'service')"
    >
      <ng-template kendoExpansionPanelTitleDirective>
        <kendo-svg-icon [icon]="allIcons.layoutSideBySideIcon"></kendo-svg-icon>
        <div class="si-expand-title">
          {{ "layout.sidenav.button_service" | translate }}
        </div>
      </ng-template>

      <!-- FEUILLE DE TEMPS -->

      <a *ngIf="canSeeSidenavFdt" class="si-sidenav-menu-item" routerLink="/fdt" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.clockIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_fdt" | translate }}</span></a
      >

      <!-- TICKET -->

      <a *ngIf="canSeeSidenavTicket" class="si-sidenav-menu-item" routerLink="/ticket" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.clipboardIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_ticket" | translate }}</span></a
      >

      <!-- TICKET TYPE-->

      <a *ngIf="canSeeSidenavTicket" class="si-sidenav-menu-item" routerLink="/ticket-type" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.clipboardIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_ticket_type" | translate }}</span></a
      >

      <!-- SERVICE CONTRACT -->

      <a *ngIf="canSeeSidenavServiceContract" class="si-sidenav-menu-item" routerLink="/service-contract" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.clipboardIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_service_contract" | translate }}</span></a
      >
    </kendo-expansionpanel>

    <!-- LOGICIEL : EXPANDABLE -->

    <kendo-expansionpanel
      class="si-sidenav-menu-item-expansionpanel"
      [class.si-sidenav-menu-item-expansionpanel-opened]="softwareExpanded"
      *ngIf="canSeeSidenavSoftware || canSeeSidenavSoftwareVersion || canSeeSidenavLicencing"
      [expanded]="softwareExpanded"
      (expandedChange)="onExpandedChange($event, 'software')"
    >
      <ng-template kendoExpansionPanelTitleDirective>
        <kendo-svg-icon [icon]="allIcons.layoutSideBySideIcon"></kendo-svg-icon>
        <div class="si-expand-title">
          {{ "layout.sidenav.button_software" | translate }}
        </div>
      </ng-template>

      <!-- SOFTWARE -->

      <a *ngIf="canSeeSidenavSoftware" class="si-sidenav-menu-item" routerLink="/software" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.gridIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_software" | translate }}</span></a
      >
      <!-- SOFTWARE VERSION -->

      <a *ngIf="canSeeSidenavSoftwareVersion" class="si-sidenav-menu-item" routerLink="/software-version" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.parameterFloatIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_software_version" | translate }}</span></a
      >
      <!-- SOFTWARE SESSION LOG -->

      <a
        *ngIf="canSeeSidenavSoftwareSessionLog"
        class="si-sidenav-menu-item"
        routerLink="/software-session-log"
        routerLinkActive="si-sidenav-menu-item-selected"
      >
        <kendo-svg-icon [icon]="allIcons.fileTxtIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_software_session_log" | translate }}</span></a
      >
      <!-- LICENCING -->

      <a *ngIf="canSeeSidenavLicencing" class="si-sidenav-menu-item" routerLink="/licencing" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.codeIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_licencing" | translate }}</span></a
      >
    </kendo-expansionpanel>

    <!-- ERP CONNECTOR : EXPANDABLE -->

    <kendo-expansionpanel
      class="si-sidenav-menu-item-expansionpanel"
      [class.si-sidenav-menu-item-expansionpanel-opened]="erpConnectorExpanded"
      *ngIf="canSeeSidenavErpCompanyConnexionInfo"
      [expanded]="erpConnectorExpanded"
      (expandedChange)="onExpandedChange($event, 'erp-connector')"
    >
      <ng-template kendoExpansionPanelTitleDirective>
        <kendo-svg-icon [icon]="allIcons.layoutSideBySideIcon"></kendo-svg-icon>
        <div class="si-expand-title">
          {{ "layout.sidenav.button_erp_connector" | translate }}
        </div>
      </ng-template>
      <!-- CONNECTION INFO -->

      <a
        *ngIf="canSeeSidenavErpCompanyConnexionInfo"
        class="si-sidenav-menu-item"
        routerLink="/erp-company-connexion-info"
        routerLinkActive="si-sidenav-menu-item-selected"
      >
        <kendo-svg-icon [icon]="allIcons.clipboardIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_erp_company_connexion_info" | translate }}</span></a
      >
      <!-- TASK HISTORY -->

      <a *ngIf="canSeeTaskHistory" class="si-sidenav-menu-item" routerLink="/task-history" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.gearsIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_task_history" | translate }}</span></a
      >
    </kendo-expansionpanel>

    <!-- SECURITY : EXPANDABLE -->

    <kendo-expansionpanel
      class="si-sidenav-menu-item-expansionpanel"
      [class.si-sidenav-menu-item-expansionpanel-opened]="securityExpanded"
      *ngIf="canSeeSidenavRole || canSeeSidenavAuditLog"
      [expanded]="securityExpanded"
      (expandedChange)="onExpandedChange($event, 'security')"
    >
      <ng-template kendoExpansionPanelTitleDirective>
        <kendo-svg-icon [icon]="allIcons.layoutSideBySideIcon"></kendo-svg-icon>
        <div class="si-expand-title">{{ "layout.sidenav.button_security" | translate }}</div>
      </ng-template>

      <!-- USER -->
      <a *ngIf="canSeeSidenavUser" class="si-sidenav-menu-item" routerLink="/user" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.userIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_user" | translate }}</span></a
      >

      <!-- ROLE -->

      <a *ngIf="canSeeSidenavRole" class="si-sidenav-menu-item" routerLink="/role" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.cancelIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_role" | translate }}</span></a
      >

      <!-- AUDIT LOGS -->

      <a *ngIf="canSeeSidenavAuditLog" class="si-sidenav-menu-item" routerLink="/system/audit-logs" routerLinkActive="si-sidenav-menu-item-selected">
        <kendo-svg-icon [icon]="allIcons.alignLeftIcon"></kendo-svg-icon>
        <span class="si-sidenav-menu-item-label">{{ "layout.sidenav.button_audit_log" | translate }}</span></a
      >
    </kendo-expansionpanel>
  </div>
</div>
