import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
  InteractionRequiredAuthError,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import { Subject, catchError } from 'rxjs';
import { filter, takeUntil, take } from 'rxjs/operators';

import { LocalStorage } from 'src/app/core/local-storage.enum';
import { AuthService } from 'src/app/core/auth/auth.service';
import { TranslationManagementService } from './core/translation/translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private authService: AuthService,
    private translate: TranslationManagementService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    // translation default & init
    var lang = localStorage.getItem(LocalStorage.LANG);
    if (!lang) {
      lang = 'fr';
      localStorage.setItem(LocalStorage.LANG, lang);
    }
    this.translate.changeCurrentLang(lang);
    // set localstorage defaults
    if (!localStorage.getItem(LocalStorage.GRID_ITEM_PER_PAGE)) localStorage.setItem(LocalStorage.GRID_ITEM_PER_PAGE, '50');
  }

  async ngOnInit() {
    this.authService.hydrate();
    const isLoginExpired = this.authService.checkIfLoginExpired();
    const isSameEnvironment = this.authService.checkIfSameEnvironment();
    const url = window.location + '';

    // logout if expired or different environment
    if (url.indexOf('reset-password') != -1) {
      this.authService.logout(false).subscribe();
    } else if (url.indexOf('signin-ms-oidc') != -1) {
      // skip logout
    } else if (isLoginExpired || !isSameEnvironment) {
      this.authService.logout(true).subscribe();
    }

    // SSO EVENTS

    // écouter les événements de connexion et se connecte au backend avec le token
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        if (payload.account.idToken) {
          this.authService.loginSSO(payload.account.idToken).pipe(take(1)).subscribe();
        }
      });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
