import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject, tap, takeUntil } from 'rxjs';
import * as svgIcons from '@progress/kendo-svg-icons';

import { AuthService } from 'src/app/core/auth/auth.service';
import { PermissionService } from 'src/app/core/permission.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  public allIcons = svgIcons;

  @Input() isExpanded: boolean;

  private unsubNotifier = new Subject<void>();

  softwareExpanded: boolean = false;
  serviceExpanded: boolean = false;
  securityExpanded: boolean = false;
  erpConnectorExpanded: boolean = false;

  canSeeSidenavUser = false;
  canSeeSidenavCustomer = false;
  canSeeSidenavLicencing = false;
  canSeeSidenavSoftware = false;
  canSeeSidenavSoftwareVersion = false;
  canSeeSidenavAuditLog = false;
  canSeeSidenavFdt = false;
  canSeeSidenavTicket = false;
  canSeeSidenavServiceContract = false;
  canSeeSidenavRole = false;
  canSeeSidenavSoftwareSessionLog = false;
  canSeeSidenavErpCompanyConnexionInfo = true;
  canSeeTaskHistory = true;

  constructor(public authService: AuthService, public permissionService: PermissionService) {
    this.isExpanded = true;
  }

  ngOnInit() {
    this.refreshSideNavRights();
    //on va ÉCOUTER le changement d'uysager en cours, et déclencher un refresh au besoin.  Le déclenchement se fait dans authService, via une méthode qui fait un next sur le currentAuthUserBehavior
    this.authService.currentAuthUserBehavior
      .pipe(
        tap((x) => {
          this.refreshSideNavRights();
        }),
        takeUntil(this.unsubNotifier)
      )
      .subscribe();
  }

  /** Cette méthode est appelée à chaque fois que l'usager change.  Elle va rafraichir les droits d'accès au menu de gauche
   *
   */
  refreshSideNavRights() {
    //security expanded zone
    let softwareExpanded = localStorage.getItem('sidenav_software_expanded');
    this.softwareExpanded = softwareExpanded == 'true';
    let serviceExpanded = localStorage.getItem('sidenav_service_expanded');
    this.serviceExpanded = serviceExpanded == 'true';
    let securityExpanded = localStorage.getItem('sidenav_security_expanded');
    this.securityExpanded = securityExpanded == 'true';
    // permissions
    this.canSeeSidenavUser = this.permissionService.canSeeSidenavUser();
    this.canSeeSidenavCustomer = this.permissionService.canSeeSidenavCustomer();
    this.canSeeSidenavLicencing = this.permissionService.canSeeSidenavLicencing();
    this.canSeeSidenavSoftware = this.permissionService.canSeeSidenavSoftware();
    this.canSeeSidenavSoftwareVersion = this.permissionService.canSeeSidenavSoftwareVersion();
    this.canSeeSidenavAuditLog = this.permissionService.canSeeSidenavAuditLog();
    this.canSeeSidenavFdt = this.permissionService.canSeeSidenavFdt();
    this.canSeeSidenavTicket = this.permissionService.canSeeSidenavTicket();
    this.canSeeSidenavServiceContract = this.permissionService.canSeeSidenavServiceContract();
    this.canSeeSidenavRole = this.permissionService.canSeeSidenavRole();
    this.canSeeSidenavErpCompanyConnexionInfo = this.permissionService.canSeeSidenavErpCompanyConnectorConnexionInfo();
    this.canSeeSidenavSoftwareSessionLog = this.permissionService.canSeeSidenavSoftwareLog();
    this.canSeeTaskHistory = this.permissionService.canSeeTaskHistory();
  }

  ngOnDestroy(): void {
    this.unsubNotifier.next();
    this.unsubNotifier.unsubscribe();
  }

  /**
   * fonction générique qui toggle les zones expandables du sidenav
   * @param event valeur de l'event true/false
   * @param key clé du localstorage
   * @param expanded variable à modifier
   */
  onExpandedChange(event: any, key: string) {
    // update localstorage
    localStorage.setItem(`sidenav_${key}_expanded`, event);
    // toggle variables
    if (key == 'service') this.serviceExpanded = event;
    if (key == 'software') this.softwareExpanded = event;
    if (key == 'security') this.securityExpanded = event;
    if (key == 'erp-connector') this.erpConnectorExpanded = event;
  }
}
