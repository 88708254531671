import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { State, toODataString } from '@progress/kendo-data-query';

import { UtilService } from 'src/app/shared/util.service';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class SystemManagerService {
  public apiInfo: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private http: HttpClient, private utilService: UtilService) {}

  getBasicApiInfo(): Observable<any> {
    return this.http.get<any>(apiUrl + 'SystemManager/GetBasicApiInfo').pipe(
      tap((data) => {
        this.apiInfo.next(data);
      })
    );
  }
}
