import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { MessageService } from '@progress/kendo-angular-l10n';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PublicClientApplication, BrowserCacheLocation, LogLevel, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalRedirectComponent, MsalModule, MsalInterceptor } from '@azure/msal-angular';

import { ErrorInterceptor } from './core/error/error.interceptor';
import { ErrorHandlerService } from './core/error/error-handler.service';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { LocalStorage } from './core/local-storage.enum';
import { TranslationManagementService } from './core/translation/translate.service';
import { environment } from 'src/environments/environment';

// translation loader

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// SSO : MSAL (Microsoft Authentication Library)

const GRAPH_ENDPOINT = 'Enter_the_Graph_Endpoint_Herev1.0/me';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set(GRAPH_ENDPOINT, ['user.read']);

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('loggerCallback', message);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.sso_microsoft.MSEntraApplicationID, //ApplicationID dans Entra
          redirectUri: environment.sso_microsoft.MSOidcRedirectUri, //chemin de retour que MS va prendre
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE,
        },
        system: {
          loggerOptions: {
            //loggerCallback: loggerCallback,
            logLevel: LogLevel.Info,
            piiLoggingEnabled: false,
          },
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ['user.read'],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap,
      }
    ),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          return localStorage.getItem(LocalStorage.JWT);
        },
        allowedDomains: [
          'api.solutionsoutien.solutioninfomedia.com',
          'dev.api.solutionsoutien.solutioninfomedia.com',
          'staging.api.solutionsoutien.solutioninfomedia.com',
          'localhost:7267',
          'localhost:7225',
        ],
      },
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: MessageService,
      useClass: TranslationManagementService,
    },
    /*
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },*/
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
