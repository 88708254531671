<div class="si-layout-container" kendoDialogContainer kendoWindowContainer>
  <kendo-drawer-container class="si-drawer-container">
    <!-- DRAWER -->
    <kendo-drawer
      #drawer
      mode="push"
      [mini]="isUserLogged"
      [expanded]="isExpanded"
      (expandedChange)="onExpandedChange($event)"
      class="si-layout-drawer"
      [class.si-sidenav-hidden]="!isUserLogged"
    >
      <!-- SIDENAV -->
      <ng-template kendoDrawerTemplate><app-sidenav [isExpanded]="isExpanded"></app-sidenav></ng-template>
    </kendo-drawer>
    <!-- MAIN -->
    <kendo-drawer-content class="si-drawer-content">
      <div class="si-layout-main">
        <!-- HEADER -->
        <div class="si-layout-header">
          <app-header (toggleSidenav)="drawer.toggle()"></app-header>
        </div>
        <!-- CONTENT -->
        <div class="si-layout-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </kendo-drawer-content>
  </kendo-drawer-container>
</div>
